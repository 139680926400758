jQuery(document).ready(function($) {

    // Function to set a cookie
    function setCookie(name, value, days) {
        let expires = "";
        if (days) {
            let date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        // Log the cookie details for debugging
        console.log('Setting cookie:', name, '=', value, expires);
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    // Function to get a cookie value
    function getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    // Function to extract numbers from a string
    function extractNumbers(str) {
        return str.replace(/\D/g, ''); // Remove non-digit characters
    }

    // Function to handle the day duration
    function setDayDurationCookie() {
        var dayDuration = $('#day_duration').text().trim(); // Ensure to trim any extra spaces
        console.log('Raw day duration text:', dayDuration); // Log the raw text

        var numericDayDuration = extractNumbers(dayDuration);
        console.log('Extracted numeric value:', numericDayDuration); // Log the numeric value

        if (numericDayDuration) { // Check if numeric value is not empty
            setCookie('Day_Duration', numericDayDuration);
        } else {
            console.error('No numeric value found for Day_Duration cookie.');
        }
    }

    // Function to apply the active class based on the cookie value
    function applyActiveClassProduct() {
        let currentCookie = getCookie('Selected_Product');
        $('li').removeClass('selected-product-active'); // Remove active class from all items
        if (currentCookie) {
            $('#' + currentCookie).addClass('selected-product-active'); // Add active class to the item with the id equal to the cookie value
        }
    }

    function applyActiveClassProductTime() {
        let currentCookie = getCookie('Selected_Product_Time');
        $('li').removeClass('product-time-variation-active'); // Remove active class from all items
        if (currentCookie) {
            $('#' + currentCookie).addClass('product-time-variation-active'); // Add active class to the item with the id equal to the cookie value
        }
    }

    function applyActiveClassProductWeekend() {
        let currentCookie = getCookie('Selected_Product_Weekend');
        $('.weekend-checkbox').removeClass('weekend-checkbox-active'); // Remove active class from all checkboxes
        if (currentCookie) {
            $('#' + currentCookie).addClass('weekend-checkbox-active'); // Add active class to the checkbox with the id equal to the cookie value
        }
    }

    // Function to navigate to the next fieldset
    function navigateToNextFieldset(currentFieldset) {
        var $currentFieldset = $(currentFieldset);
        $currentFieldset.hide();
        $currentFieldset.next('fieldset').show();

        updateProgressBar(); // Update progress bar when navigating to the next fieldset

        // Scroll to #wp-block-cab-abonament section
        $('html, body').animate({
            scrollTop: $('#wp-block-cab-abonament').offset().top
        }, 800); // Adjust scroll speed as needed
    }

    // Function to navigate to the previous fieldset
    function navigateToPreviousFieldset(currentFieldset) {
        var $currentFieldset = $(currentFieldset);
        $currentFieldset.hide();
        $currentFieldset.prev('fieldset').show();

        updateProgressBar('back'); // Update progress bar when navigating to the previous fieldset

        // Scroll to #wp-block-cab-abonament section
        $('html, body').animate({
            scrollTop: $('#wp-block-cab-abonament').offset().top
        }, 800); // Adjust scroll speed as needed
    }

    // Function to update the progress bar
    function updateProgressBar(direction = 'next') {
        var $visibleFieldset = $('fieldset:visible');
        var currentStep = $visibleFieldset.index() + 1; // Get the current step number (1-based index)
        var stepClass = 'step-' + currentStep; // Generate the class name based on the step

        if (direction === 'next') {
            $('.' + stepClass).addClass('step-active'); // Add active class to all elements with the current step class
        } else if (direction === 'back') {
            var prevStepClass = 'step-' + (currentStep + 1);
            $('.' + prevStepClass).removeClass('step-active'); // Remove active class from all elements with the previous step class
        }
    }

    // Function to copy and split UserName to billing_first_name and billing_last_name
    function copyUserNameToBilling() {
        var userName = $('#UserName').val().trim(); // Get the value from the UserName input and trim any extra spaces
        var nameParts = userName.split(' ', 2); // Split the value into first and second part
        var adressCity= $('#UserCity').val();
        var adressStreet = $('#UserAdres').val();
        var adressHouseNumber = $('#UserHomeNumber').val();
        var adressPostCode = $('#UserPostCode').val();
        var userPhone = $('#UserPhone').val();
        var userEmail = $('#UserEmail').val();

        if (nameParts.length > 0) {
            $('#billing_first_name').val(nameParts[0]); // Set the first part as the first name
        }
        if (nameParts.length > 1) {
            $('#billing_last_name').val(nameParts[1]); // Set the second part as the last name
        } else {
            $('#billing_last_name').val(''); // Clear the last name if there is no second part
        }
        $('#billing_city').val(adressCity);
        $('#billing_address_1').val(adressStreet + '' + adressHouseNumber);
        $('#billing_postcode').val(adressPostCode);
        $('#billing_phone').val(userPhone);
        $('#billing_email').val(userEmail);
        $('#order-summary-customer-location').text(adressCity+ ',' + adressStreet + '' + adressHouseNumber);
    }

    // Attach the copyUserNameToBilling function to the UserName input change event
    $(document).on('input', '#UserName', function() {
        copyUserNameToBilling();
    });
    // Attach the copyUserNameToBilling function to the UserAdresCity input change event
    $(document).on('input', '#UserCity', function() {
        copyUserNameToBilling();
    });
    // Attach the copyUserNameToBilling function to the UserAdres input change event
    $(document).on('input', '#UserAdres', function() {
        copyUserNameToBilling();
    });
    // Attach the copyUserNameToBilling function to the UserAdresHouseNumber input change event
    $(document).on('input', '#UserHomeNumber', function() {
        copyUserNameToBilling();
    });
    // Attach the copyUserNameToBilling function to the UserAdresHouseNumber input change event
    $(document).on('input', '#UserPostCode', function() {
        copyUserNameToBilling();
    });
    // Attach the copyUserNameToBilling function to the UserAdresHouseNumber input change event
    $(document).on('input', '#UserPhone', function() {
        copyUserNameToBilling();
    });
    $(document).on('input', '#UserEmail', function() {
        copyUserNameToBilling();
    });
    // Initial call to set the correct step on page load
    updateProgressBar();

    // Handle next button click
    $(document).on('click', '.next', function() {
        var $this = $(this);

        if ($this.attr('id') === 'render-product-attributes') {
            // Verify if AJAX URL is defined
            if (typeof CAB_loc !== 'undefined' && typeof CAB_loc.ajax_url === 'string') {
                console.log('AJAX URL:', CAB_loc.ajax_url); // Log AJAX URL for verification

                // Send AJAX request to WordPress AJAX handler
                $.ajax({
                    url: CAB_loc.ajax_url, // Use localized variable for AJAX URL
                    type: 'POST',
                    data: {
                        action: 'render_product_attributes' // This should match the action name registered in PHP
                    },
                    success: function(response) {
                        console.log('AJAX Response:', response); // Log the response for debugging
                        $('#productVariationsFieldset').html(response).show();

                        // Hide the current fieldset and show the next one
                        navigateToNextFieldset($this.closest('fieldset'));
                    },
                    error: function(xhr, status, error) {
                        console.error('AJAX Error:', status, error); // Log errors if any
                    }
                });
            } else {
                console.error('AJAX URL is not defined. Check if the script is correctly enqueued and localized.');
            }
        } else {
            // Regular next button click handler if it's not the render-product-attributes button
            navigateToNextFieldset($this.closest('fieldset'));
        }
    });

    // Delegate event to dynamically loaded elements for "Back" button
    $(document).on('click', '.back', function() {
        var $this = $(this);
        navigateToPreviousFieldset($this.closest('fieldset'));
    });

    // Handle click on product-time-variation
    $(document).on('click', '.product-time-variation', function() {
        let divId = $(this).attr('id');
        setCookie('Selected_Product_Time', divId);
        applyActiveClassProductTime();

        // Optionally, you can log the cookie value to confirm it's set
        console.log('Cookie set: ' + document.cookie);
    });

    // Handle click on weekend-checkbox
    $(document).on('click', '.weekend-checkbox', function() {
        let divId = $(this).attr('id');
        setCookie('Selected_Product_Weekend', divId);
        applyActiveClassProductWeekend();

        // Optionally, you can log the cookie value to confirm it's set
        console.log('Cookie set: ' + document.cookie);
    });

    // Handle change on #date input
    $(document).on('change', '#date', function() {
        var dateValue = $(this).val();
        setCookie('Starting_Date', dateValue);

        // Optionally, you can log the cookie value to confirm it's set
        console.log('Cookie set: ' + document.cookie);
    });

    // Handle value of day duration
    function setDayDurationCookie() {
        var dayDuration = $('#day_duration').text().trim(); // Ensure to trim any extra spaces
        console.log('Raw day duration text:', dayDuration); // Log the raw text

        var numericDayDuration = extractNumbers(dayDuration);
        console.log('Extracted numeric value:', numericDayDuration); // Log the numeric value

        if (numericDayDuration) { // Check if numeric value is not empty
            setCookie('Day_Duration', numericDayDuration);
        } else {
            console.error('No numeric value found for Day_Duration cookie.');
        }
    }

    // Call setDayDurationCookie on page load
    setDayDurationCookie();

    // Handle change on weekend-checkbox to allow only one checked
    $(document).on('change', '.weekend-checkbox', function() {
        $('.weekend-checkbox').not(this).prop('checked', false);
    });

    // Handle date input click
    $(document).on('click', '#date', function() {
        $(this)[0].showPicker(); // Trigger the native date picker if possible
        // Fallback: focus the date input
        $(this).focus();
    });

    // Handle click on add-to-cart-button
    $(document).on('click', '#add-to-cart-button', function(e) {
        e.preventDefault();

        var $button = $(this);
        var product_id = $('#product-id').val();
        var quantity = $('#quantity').val();
        var nonce = $button.data('nonce');
        var ajaxurl = $button.data('ajax-url');
        var variation = {
            'variation_id': $('#' + product_id).val(),
            'attributes': {
                'attribute_pa_czas': $('#attribute-pa-czas').val(),
                'attribute_pa_weekend': $('#attribute-pa-weekend').val()
            }
        };
        var custom_value = 'some_custom_value'; // Replace with actual custom value if needed

        $.ajax({
            url: ajaxurl,
            type: 'POST',
            data: {
                action: 'add_custom_product_to_cart',
                nonce: nonce,
                product_id: product_id,
                quantity: quantity,
                variation: variation,
                custom_value: custom_value
            },
            success: function(response) {
                if (response.success) {
                    alert('Product added to cart successfully.');
                    // Optionally, update the cart UI or redirect to the cart page
                } else {
                    alert('Error: ' + response.data.message);
                }
            },
            error: function(xhr, status, error) {
                console.log(xhr.responseText);  // Log the response for debugging
                alert('An error occurred. Please try again.\nStatus: ' + status + '\nError: ' + error);
            }
        });
    });
    $('.clear-cart').on('click', function() {
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'POST',
            data: {
                action: 'clear_cart'
            },
            success: function(response) {
                if (response.success) {
                    console.log('Cart cleared successfully.');
                    // Optionally, you can redirect to a new page or refresh the page
                    // location.reload(); // Uncomment this line if you want to reload the page
                } else {
                    console.error('Failed to clear cart.');
                }
            },
            error: function() {
                console.error('AJAX request failed.');
            }
        });
    });
    // Handle click on .product-single elements
    $(document).on('click', '.product-single', function() {
        let divId = $(this).attr('id');
        setCookie('Selected_Product', divId);
        applyActiveClassProduct();

        // Verify if AJAX URL is defined
        if (typeof CAB_loc !== 'undefined' && typeof CAB_loc.ajax_url === 'string') {
            // Send AJAX request to WordPress AJAX handler
            $.ajax({
                url: CAB_loc.ajax_url, // Use localized variable for AJAX URL
                type: 'POST',
                data: {
                    action: 'my_custom_action', // This should match the action name registered in PHP
                    selectedProduct: divId
                },
                success: function(response) {
                    console.log('Response from server:', response);
                },
                error: function(xhr, status, error) {
                    console.error('AJAX Error:', status, error);
                }
            });
        } else {
            console.error('AJAX URL is not defined. Check if the script is correctly enqueued and localized.');
        }

        // Optionally, you can log the cookie value to confirm it's set
        console.log('Cookie set: ' + document.cookie);
    });

    // Call functions to apply the active class on page load
    applyActiveClassProduct();
    applyActiveClassProductTime();
    applyActiveClassProductWeekend();
    setDayDurationCookie();
});
