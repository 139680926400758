import "./blocks/hero/script";
import "./blocks/list/script";
import "./blocks/range-list/script";
import "./blocks/abonament/script";
import "./blocks/homepage-hero/script";
import "./blocks/text-section/script";
import "./blocks/image-gallery/script";
import "./blocks/locations/script";
import "./blocks/menu-block/script";
import "./blocks/contact-info/script";
import "./blocks/contact-form/script";
