jQuery( document ).ready( function( $ ) {
    // function HideRange() {
    //     $('.cab-range-item-single[data-id]').each(function () {
    //         var dataId = parseInt($(this).data('id')); // Get the value of data-id as an integer
    //
    //         // Check if data-id is greater than 3
    //         if (dataId > 3) {
    //             $(this).hide(); // Hide the element
    //         }
    //     });
    //     $(".cab-showmore-posts-txt").click(function () {
    //         $('.cab-showmore-posts').hide();
    //         $('.cab-range-item-single[data-id]').each(function () {
    //             var dataId = parseInt($(this).data('id')); // Get the value of data-id as an integer
    //
    //             // Check if data-id is greater than 3
    //             if (dataId > 3) {
    //                 $(this).show(); // Show the element
    //             }
    //         });
    //     });
    // }
    // HideRange();
});
